<template>
  <div>
    <v-layout v-if="device">
      <v-btn
        icon
        class="primary--text mt-1 mr-2"
        :to="{
          name: 'devices',
          query: {
            pageToken: pagination.currentPageToken,
          },
        }"
        router
        exact
      >
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>

      <page-title>Device '{{ device.name }}'</page-title>

      <div class="ml-4 mt-1 mb-2">
        <!-- TODO: button to add a device -->
        <v-btn class="primary--text mr-3" text @click="refresh()">
          <v-icon class="mr-1">refresh</v-icon>
          Refresh
        </v-btn>
        <v-btn
          v-if="device"
          :disabled="device.status != 'online'"
          class="primary--text mr-3"
          text
          :to="{
            name: 'deviceSSH',
            params: {
              project: device.projectId,
              device: device.id,
            },
          }"
          router
        >
          <v-icon class="mr-1">mdi-television</v-icon>
          SSH
        </v-btn>
        <device-maintenance :device="device"></device-maintenance>
      </div>
    </v-layout>

    <v-layout>
      <page-subtitle
        >View, edit your device details and application allocations
        here.</page-subtitle
      >
    </v-layout>

    <!-- name edit -->
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit device name</span>
        </v-card-title>

        <v-card-text>
          <form v-on:submit.prevent="save()">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-btn v-show="false" hidden type="submit"></v-btn>
          </form>
        </v-card-text>

        <v-card-text v-if="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary--text" text @click="close"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- version edit -->
    <v-dialog v-model="versionDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit device version</span>
        </v-card-title>

        <v-card-text>
          <p v-if="device && device.info">
            Editing device version will trigger an automatic update.
          </p>
           <div v-if="device != undefined && device.info != undefined">
            <p>Current version: {{ device.info.agentVersion }}</p>
           </div>
          <form v-on:submit.prevent="save()">
            <v-container>
              <v-row align="center">
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    :items="availableDeviceVersions"
                    label="Available versions"
                    v-model="editedItem.desiredAgentVersion"
                    solo
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="editedItem != undefined && device.info != undefined &&
                  editedItem.desiredAgentVersion !== '' &&
                  editedItem.desiredAgentVersion !== device.info.agentVersion
                "
                align="center"
              >
                <v-col cols="12" sm="12" md="12">
                  <v-alert dense outlined type="info">
                    Device will change version from
                    <strong>{{ device.info.agentVersion }}</strong> to
                    <strong>{{ editedItem.desiredAgentVersion }}</strong
                    >.
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
            <v-btn v-show="false" hidden type="submit"></v-btn>
          </form>
        </v-card-text>

        <v-card-text v-if="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-btn class="primary--text" text @click="close"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primary--text" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <v-card v-if="device" flat outlined height="100%">
          <v-card-title>Device Info</v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item v-if="device">
                <v-list-item-content>
                  <span class="font-weight-bold">Device ID</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.id }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device">
                <v-list-item-content>
                  <span class="font-weight-bold">Device Name</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.name }}
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon small class="mr-2" @click="editName()">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device">
                <v-list-item-content>
                  <span class="font-weight-bold">Device Hostname</span>
                </v-list-item-content>
                <v-list-item-content v-if="device.info" class="align-end">
                  {{ device.info.hostname }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end">
                  -
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device">
                <v-list-item-content>
                  <span class="font-weight-bold">Device Architecture</span>
                </v-list-item-content>
                <v-list-item-content v-if="device.info" class="align-end">
                  {{ device.info.architecture }}
                </v-list-item-content>
                <v-list-item-content v-else class="align-end">
                  -
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">Agent Version</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <span
                    v-if="
                      device.desiredAgentVersion !== '' &&
                      device.info.agentVersion !== device.desiredAgentVersion
                    "
                  >
                    <!-- Update should be in progress -->
                    {{ device.info.agentVersion }} ->
                    {{ device.desiredAgentVersion }}
                    <v-progress-circular
                      class="ml-2"
                      :width="2"
                      :size="13"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </span>
                  <span v-else>
                    <!-- updated -->
                    {{ device.info.agentVersion }}
                  </span>
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                  <v-icon small class="mr-2" @click="editDesiredAgentVersion()">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">IP Address</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.info.ipAddress }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">Operating System</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.info.osRelease.prettyName }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">Operating System Family</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.info.osRelease.idLike }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">CPU</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-container class="pl-0" :fluid="true">
                    <v-row align="left" no-gutters>
                      <v-col md="4">Name</v-col>
                      <v-col md="8">{{ device.info.cpuInfo.brandName }}</v-col>
                    </v-row>

                    <v-row class="mt-3" align="left" no-gutters>
                      <v-col md="4">Physical Cores</v-col>
                      <v-col md="8">{{ device.info.cpuInfo.physicalCores }}</v-col>
                    </v-row>

                    <v-row class="mt-3" align="left" no-gutters>
                      <v-col md="4">Threads</v-col>
                      <v-col md="8">{{ device.info.cpuInfo.threadsPerCore }}</v-col>
                    </v-row>

                    <v-row class="mt-3" align="left" no-gutters>
                      <v-col md="4">Logical Cores</v-col>
                      <v-col md="8">{{ device.info.cpuInfo.logicalCores }}</v-col>
                    </v-row>
                  </v-container>
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info && device.info.gpusInfo && device.info.gpusInfo.length > 0">
                <v-list-item-content>
                  <span class="font-weight-bold">GPUs ({{ device.info.gpusInfo.length }})</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  <v-container class="pl-0" :fluid="true">

                    <template
                      v-for="(gpu, i) in device.info.gpusInfo"
                    >
                      <v-row class="mt-3" :key="`vendor-${i}`" align="left" no-gutters>
                        <v-col md="4">Name</v-col>
                        <v-col md="8">{{ gpu.productName }}</v-col>
                      </v-row>

                      <v-row class="mt-3" :key="i" align="left" no-gutters>
                        <v-col md="4">Memory</v-col>
                        <v-col md="8">{{ gpu.memory }}</v-col>
                      </v-row>
                    </template>                
                  </v-container>
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">Added to Synpse</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.createdAt | date }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">Last Seen</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.lastSeenAt | date }}
                </v-list-item-content>
                <v-list-item-icon>
                  <!-- just for alignment, not editable -->
                </v-list-item-icon>
              </v-list-item>

              <v-list-item v-if="device.info">
                <v-list-item-content>
                  <span class="font-weight-bold">Status</span>
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ device.status }}
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="device.status == 'online'"
                    small
                    color="green accent-3"
                    >fiber_manual_record</v-icon
                  >
                  <v-icon
                    v-else-if="device.status == 'offline'"
                    small
                    color="error"
                    >fiber_manual_record</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex lg4 md12 sm12 pr-2>
        <!-- <v-layout wrap> -->
        <v-card flat outlined  class="">
           <v-card-title>Public URL
           
           <v-spacer></v-spacer>
            <v-chip
              class="ma-2"
              color="green accent-3"
              outlined
            >
              <v-icon left class="mr-2">
                mdi-alert-decagram
              </v-icon>
              BETA
            </v-chip>
           </v-card-title>
           <v-card-text>


             <v-form>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                     <v-switch
                        v-model="deviceTunnel"
                        :label="`${deviceTunnel ? 'Enabled' : 'Disabled'}`"
                      ></v-switch>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="deviceTunnelLocalPort"
                      label="Local Device Port"
                      outlined
                      type="number"
                      :rules="deviceTunnelLocalPortRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>            

            <p v-if="deviceTunnelEnabled">
              <a :href="this.deviceTunnelPublicURL" target="_blank" rel="noopener">
                {{ this.deviceTunnelPublicURL != '' ? this.deviceTunnelPublicURL : '-' }}
              </a>
            </p>

            <v-alert
              dense
              v-if="currentDeviceTunnelLocalPort != deviceTunnelLocalPort"
              outlined
              type="info"
            >
              After modifying local device port, please disable/enable the tunnel to update the configuration.
            </v-alert>

            <p>
              Tunnel uses client initiated connections to route traffic. These tunnels will work with 
              satellite, cellular and landline networks even if you can't ping device directly.
            </p>

           </v-card-text>
        </v-card>

        <v-card v-if="metrics.length > 0" flat outlined class="mt-2">
          <v-card-title>Metrics</v-card-title>
          <v-card-text>
            <vue-frappe
              id="cpu"
              :labels="utilisationMetricLabels"
              title="CPU"
              type="axis-mixed"
              :lineOptions="{
                regionFill: 1,
                hideDots: 1,
              }"
              :height="200"
              :colors="['#e040fb']"
              :tooltipOptions="{
                formatTooltipX: (d) => (d + '').toUpperCase(),
                formatTooltipY: (d) => d + '%',
              }"
              :axisOptions="{
                xIsSeries: true, // default: false
              }"
              :yMarkers="[
                {
                  label: '',
                  value: 100,
                  type: 'solid',
                },
              ]"
              :discreteDomains="false"
              :dataSets="cpuUtilisationMetricChartData"
            >
            </vue-frappe>

            <vue-frappe
              id="ram"
              :labels="utilisationMetricLabels"
              title="RAM"
              type="axis-mixed"
              :lineOptions="{
                regionFill: 1,
                hideDots: 1,
              }"
              :height="200"
              :colors="['#e040fb', '#7cd6fd']"
              :tooltipOptions="{
                formatTooltipX: (d) => (d + '').toUpperCase(),
                formatTooltipY: (d) => d + 'GB',
              }"
              :axisOptions="{
                xIsSeries: true, // default: false
              }"
              :discreteDomains="false"
              :dataSets="ramUtilisationMetricChartData"
            >
            </vue-frappe>
          </v-card-text>
        </v-card>
        <v-card v-else flat outlined>
          <v-card-text>CPU metrics are not available.</v-card-text>
        </v-card>
        <!-- </v-layout> -->
      </v-flex>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <device-labels-edit v-if="device" :device="device"></device-labels-edit>
      </v-flex>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <device-env-vars-edit
          v-if="device"
          :device="device"
        ></device-env-vars-edit>
      </v-flex>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <device-applications v-if="device" :device="device">
        </device-applications>
      </v-flex>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pr-2>
        <v-card
          flat
          outlined
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Device details
              </v-expansion-panel-header>
              <v-expansion-panel-content id="innerExPan">
                <v-list-item v-if="device.info">
                  <v-list-item-content>
                    <span class="font-weight-bold">Container runtime</span>
                  </v-list-item-content>
                  <v-list-item-content class="align-end">
                      <v-switch
                          v-model="dockerEnableState"
                          :label="`${dockerEnableState ? 'Enabled' : 'Disabled'}`"
                      ></v-switch>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <!-- just for alignment, not editable -->
                  </v-list-item-icon>
                </v-list-item>

                <v-card-text  v-if="
                    device &&
                    device.info &&
                    device.info.docker &&
                    device.info.docker.version !== ''
                  ">
                  Container runtime
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Docker version</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.version }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Bridge IP</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.bridgeIP }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">OS Type</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.osType }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Privileged Enabled</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.privilegedEnabled }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Runtimes</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.runtimes }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Health</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.health }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Health Description</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.docker.healthDescription }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-card-text>

                <v-card-text>
                  Synpse agent
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Log level</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.agentLogLevel }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <span class="font-weight-bold">Agent version</span>
                      </v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ device.info.agentVersion }}
                      </v-list-item-content>
                      <v-list-item-icon>
                        <!-- just for alignment, not editable -->
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style lang="stylus">
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.line-horizontal, .line-vertical {
  display: none;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import DeviceLabelsEdit from './DeviceLabelsEdit';
import DeviceEnvVarsEdit from './DeviceEnvVarsEdit';
import DeviceApplications from './DeviceApplications';
import DeviceMaintenance from './DeviceMaintenance.vue';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    DeviceLabelsEdit,
    DeviceEnvVarsEdit,
    DeviceApplications,
    DeviceMaintenance
  },
  data() {
    return {
      dialog: false,
      versionDialog: false,
      editedItem: {},
      dockerEnableState: true,
      deviceTunnel: false,
      deviceTunnelLocalPort: 80,

      deviceTunnelLocalPortRules: [
        value => !!value || 'Required.',
        value => (value || '') >  1 || 'Port cannot be lower than 1',
        value => (value || '') <  65535 || 'Port cannot be higher than 65535'
      ],

      chartData: [
        {
          name: "CPU Utilization",
          chartType: 'line',
          values: [15, 20, -3, -15, 58, 12, -17, 37]
        }]
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    versionDialog(val) {
      val || this.close()
    },
    deviceTunnel(val) {
      if (val) {
        // turning on the tunnel
        let payload = {
          projectId: this.device.projectId,
          deviceId: this.device.id,
          enabled: true,
          localPort: parseInt(this.deviceTunnelLocalPort, 10)
        }
        this.$store.dispatch('UpdateDeviceTunnels', payload).then(() => {
          if (this.error) {
            this.$store.dispatch('Notify', `${this.error}`)
          }             
          this.refresh()
        })
      } else {
        // turning off the tunnel
        let payload = {
          projectId: this.device.projectId,
          deviceId: this.device.id,
          enabled: false, 
          localPort: parseInt(this.deviceTunnelLocalPort, 10)
        }
        this.$store.dispatch('UpdateDeviceTunnels', payload).then(() => {
          if (this.error) {
            this.$store.dispatch('Notify', `${this.error}`)
          }
          this.refresh()
        })
      }
    },
    dockerEnableState(val) {
      this.editedItem = Object.assign({}, this.device)
      this.editedItem.dockerRuntimeDisabled = !val
      console.log(this.editedItem.dockerRuntimeDisabled)
      this.save()
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    deviceId() {
      return this.$route.params.device
    },
    device() {
      return this.$store.state.device.device
    },
    deviceTunnelEnabled() {
      if (!this.device) {
        return false
      }

      if (this.device.tunnels && this.device.tunnels.webhookRelay && this.device.tunnels.webhookRelay.length > 0) {
        // We have one tunnel configured
        return true
      }
      
      return false
    },
    dockerEnabled() {
      if (this.device == undefined || this.device.agentSettings == undefined) {
        return false
      }
      return !this.device.agentSettings.dockerRuntimeDisabled
    },
    deviceTunnelPublicURL() {
      if (!this.deviceTunnelEnabled) {
        return ''
      }
   
      return `https://${this.device.tunnels.webhookRelay[0].domain}`
    },
    // currentDeviceTunnelLocalPort returns current device local port
    // from a configured tunnel
    currentDeviceTunnelLocalPort() {
      if (!this.deviceTunnelEnabled) {
        // Returning same as we would set if it's not there
        return this.deviceTunnelLocalPort
      }
   
      return this.device.tunnels.webhookRelay[0].localPort
    },
    availableDeviceVersions() {
      return this.$store.state.device.versions
    },
    metrics() {
      return this.$store.state.device.deviceMetrics
    },
    utilisationMetricLabels() {
      let labels = ['']
      for (var metric of this.$store.state.device.deviceMetrics) {
        labels.push(metric.timestamp);
      }
      return labels
    },
    cpuUtilisationMetricChartData() {
      let chartData = {
        name: "CPU Utilization",
        chartType: 'line',
        regionFill: 1,
        values: []
      }

      for (var metric of this.$store.state.device.deviceMetrics) {
        chartData.values.push(metric.cpuUtilisation)
      }
      return [chartData]
    },
    ramUtilisationMetricChartData() {
      let chartDataUsedRAM = {
        name: "Used",
        chartType: 'line',
        regionFill: 1,
        values: []
      }

      let chartDataTotalRAM = {
        name: "Total",
        chartType: 'line',
        regionFill: 1,
        values: []
      }

      for (var metric of this.$store.state.device.deviceMetrics) {
        chartDataUsedRAM.values.push(metric.memUsed)
        chartDataTotalRAM.values.push(metric.memTotal)
      }

      return [chartDataUsedRAM, chartDataTotalRAM]
    },
    loading() {
      return this.$store.state.device.loading
    },
    error() {
      return this.$store.state.device.error
    },
    pagination() {
      return this.$store.state.device.devicesPagination
    }
  },

  mounted() {
    this.subscribe()
    this.refresh()
    this.getAvailableDeviceVersions()
  },

  beforeDestroy() {
    this.$store.dispatch('UnsubscribeFromSSE')
    this.$store.dispatch('ResetDeviceMetrics')
  },

  beforeUnmount() {
    this.$store.dispatch('UnsubscribeFromSSE')
    this.$store.dispatch('ResetDeviceMetrics')
  },

  methods: {
    getAvailableDeviceVersions() {
      let q = {
        projectId: this.projectId
      }
      this.$store.dispatch('GetDeviceVersions', q)
    },
    refresh() {
      let q = {
        projectId: this.projectId,
        deviceId: this.deviceId
      }
      this.$store.dispatch('GetDevice', q).then(() => {
        this.deviceTunnel = this.deviceTunnelEnabled
        this.deviceTunnelLocalPort = this.currentDeviceTunnelLocalPort
        this.dockerEnableState = this.dockerEnabled
      })
      this.$store.dispatch('GetDeviceMetrics', q)
    },
    subscribe() {
      let token = this.$store.getters.jwt
      let streamUrl = `/api/projects/${this.projectId}/events?stream=${this.projectId}:devices:${this.deviceId}&_t=${token}`

      let q = {
        stream: streamUrl,
        callback: this.refresh
      }

      this.$store.dispatch('SubscribeToSSE', q)

    },
    close() {
      this.dialog = false
      this.versionDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.device)
      })
    },
    editName() {
      this.editedItem = Object.assign({}, this.device)
      this.dialog = true
    },
    editDesiredAgentVersion() {
      this.editedItem = Object.assign({}, this.device)
      this.versionDialog = true
    },
    save() {
      this.$store.dispatch('UpdateDevice', this.editedItem).then(() => {
        if (this.error) {
          return
        }
        this.close()
        this.refresh()
      })
    },
  }
}
</script>
